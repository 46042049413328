import { createSelector } from 'reselect';

const regions = state => state.commons.regions || [];

const formatRegions = (regions) => {
  return regions.map(r => r.name).sort((a, b) => a.localeCompare(b))
};

const getRegions = createSelector(
  regions, (regions) => {
    return formatRegions(regions)
  }
);

const getAcademies = region => createSelector(
  regions, (regions) => {
    const r = regions.find(e => e.name === region);
    return r ? r.academies.sort((a, b) => a.localeCompare(b)) : []
  }
);

export default {
  getRegions,
  getAcademies,
};
